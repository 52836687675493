@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;600&display=swap);
.forms-body {
  display: -webkit-flex;
  display: flex;
  height: 90vh;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: linear-gradient(135deg, #39a9cb, #03256c);
  padding: 10px;
  font-family: "Poppins", sans-serif;
}

.containers {
  max-width: 700px;
  width: 100%;
  background-color: #fff;
  padding: 25px 30px;
  border-radius: 10px;
}
.containers .title {
  font-size: 26px;
  font-weight: 600;
  position: relative;
}

.containers .title::before {
  content: " ";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 30px;
  background: linear-gradient(135deg, #39a9cb, #03256c);
}

.containers form .user-details {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: 20px 0 12px 0;
}
.success-message {
  margin: 10px 0;
  color: #61b15a;
  font-weight: 400;
}
.alert-message {
  color: red;
  font-size: 10px;
  transition: all 0.3s ease;
}
form .user-details .input-box {
  width: calc(100% / 2 - 20px);
  margin-bottom: 15px;
}
.user-details .input-box input {
  height: 45px;
  width: 100%;
  outline: none;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding-left: 15px;
  font-size: 16px;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
}
.user-details .input-box .details {
  font-weight: 500;
  margin-bottom: 5px;
  display: block;
}

.user-details .input-box input:focus {
  border-color: #03256c;
}

.form-button {
  width: 100%;
  height: 100%;
  border: none;
  color: #fff;
  font-weight: 600;
  letter-spacing: 1px;
  border-radius: 5px;
  background: linear-gradient(135deg, #39a9cb, #03256c);
  padding: 10px 0px;
}
.form-button:hover {
  background: linear-gradient(-135deg, #39a9cb, #03256c);
}

@media (max-width: 584px) {
  .containers {
    max-width: 100%;
  }
  form .user-details .input-box {
    width: 100%;
    margin-bottom: 15px;
  }
  .containers form .user-details {
    max-height: 300px;
    overflow-y: scroll;
  }
  .user-details::-webkit-scrollbar {
    width: 0;
  }
}



